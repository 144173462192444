import { Board } from '@air/api/types';
import classnames from 'classnames';
import { ReactNode, useState } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';

import { useBoardPermissionsCache } from '~/hooks/useBoardPermissionsCache';
import { selectedBoardsSelector } from '~/store/selectedItems/selectors';
import { canAddAssetsToBoard, canCreateBoard, canMoveAssetsToBoard } from '~/utils/permissions/boardPermissions';
import { useAirStore } from '~/utils/ReduxUtils';

import dragTypes, { boardDragTypes, DropData, DropLocation, isBoardDragType } from '../dragTypes';
import { canMoveBoardsToBoard } from './methods';

export type DNDParentBoardInterface = Pick<Board, 'id' | 'ancestors' | 'title'>;

export interface DNDParentBoardProps {
  board: DNDParentBoardInterface;
  children: ReactNode;
  dropLocation: DropLocation;
}

export const DNDParentBoard = ({ board, children, dropLocation }: DNDParentBoardProps) => {
  const [canDrop, setCanDrop] = useState(false);
  const store = useAirStore();
  const selectedBoards = selectedBoardsSelector(store.getState());
  const { getBoardPermissions } = useBoardPermissionsCache();

  const checkIfCanDrop = (monitor: DropTargetMonitor) => {
    // this comes from the `getItem` of DNDMovableToBoardItem
    const item = monitor.getItem();

    const permissions = getBoardPermissions(board.id);

    // this function determines whether or not the dragged item can be dropped here
    // we only want to check if there are selected boards or the user has physically dragged a board
    if ((isBoardDragType(item.type) || selectedBoards.length) && canCreateBoard(permissions)) {
      return canMoveBoardsToBoard(isBoardDragType(item.type) ? [...selectedBoards, item] : selectedBoards, board);
    } else {
      return canAddAssetsToBoard(permissions) || canMoveAssetsToBoard(permissions);
    }
  };

  const [{ isOver }, drop] = useDrop({
    accept: [...boardDragTypes, dragTypes.asset, dragTypes.file],
    drop: (): DropData => ({
      type: 'move',
      location: dropLocation,
      data: board,
    }),
    hover: (_props, monitor) => {
      const canDropItem = checkIfCanDrop(monitor);
      if (canDropItem !== canDrop) {
        setCanDrop(canDropItem);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    canDrop: (_props, monitor) => checkIfCanDrop(monitor),
  });

  return (
    <div
      data-draggable="true"
      className={classnames('relative h-full', {
        'hovering-drop': isOver && canDrop,
      })}
      ref={drop}
    >
      {children}
    </div>
  );
};
