import { Button } from '@air/primitive-button';
import { Formik } from 'formik';

import Form from '~/components/Form';
import { FormikInput } from '~/components/Zephyr/Formik/FormikInput';

export type LibraryDeleteConfirmationModalFormProps = {
  name: string;
  onClose: () => void;
  onSubmit: (values: { name: string }) => void;
};

export const LibraryDeleteConfirmationModalForm = ({
  name,
  onClose,
  onSubmit,
}: LibraryDeleteConfirmationModalFormProps) => {
  return (
    <Formik initialValues={{ name: '' }} onSubmit={onSubmit}>
      {({ values }) => (
        <Form className="mt-4">
          <FormikInput label={`Type ${name} to confirm`} name="name" placeholder={name} />

          <div className="mt-6 flex justify-end gap-3">
            <Button appearance="ghost" color="grey" onClick={onClose} size="large">
              Cancel
            </Button>
            <Button appearance="filled" color="red" disabled={values.name !== name} size="large" type="submit">
              Delete
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
