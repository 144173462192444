import classNames from 'classnames';
import { ComponentProps, ReactNode } from 'react';

import { Avatar } from '~/components/Avatar';

export type LibraryInviteMemberListItemProps = Pick<ComponentProps<'div'>, 'className'> & {
  colorSeed?: string;
  avatar?: string;
  label: string;
  meta?: string;
  suffix?: ReactNode;
};

export const LibraryInviteMemberListItem = ({
  avatar,
  className,
  colorSeed,
  label,
  meta,
  suffix,
}: LibraryInviteMemberListItemProps) => {
  const initials = label
    .split(' ')
    .map((word) => word[0])
    .join('');

  return (
    <div
      className={classNames(
        'flex h-12 grow cursor-pointer items-center gap-3 rounded px-2 hover:bg-pigeon-50',
        className,
      )}
    >
      <Avatar
        alt={`${label} avatar`}
        appearance="circle"
        className="shrink-0"
        colorSeed={colorSeed}
        size={32}
        src={avatar}
        text={initials}
      />

      <div className="flex grow flex-col">
        <div className="text-14 font-medium text-pigeon-600">{label}</div>

        {!!meta && <div className="text-12 text-pigeon-400">{meta}</div>}
      </div>

      {!!suffix && <div className="shrink-0 text-12 capitalize text-pigeon-400">{suffix}</div>}
    </div>
  );
};
