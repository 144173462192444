import { WorkspaceUserRole } from '@air/api/types';
import { TransactionModal } from '@air/zephyr';

export interface RoleChangeConfirmationModalProps {
  onConfirm: () => void;
  currentRole: WorkspaceUserRole['displayName'];
  newRole: WorkspaceUserRole['displayName'];
}

export const RoleChangeConfirmationModal = ({
  onClose,
  onConfirm,
  currentRole,
  newRole,
}: AirModalProps<RoleChangeConfirmationModalProps>) => {
  const onSelectYes = () => {
    onConfirm();
    onClose();
  };

  return (
    <TransactionModal
      onDismiss={onClose}
      modalLabel="Are you sure?"
      modalDescription={`If you change your role from ${currentRole} to ${newRole} you will need to contact someone with higher permissions to change it back for you.`}
      primaryCTA={{
        children: `Change Role`,
        onClick: onSelectYes,
        variant: 'button-filled-destructive',
      }}
      secondaryCTA={{ children: 'Cancel', onClick: onClose }}
    />
  );
};
