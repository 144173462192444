import { useTrackCustomFieldAddOption } from '@air/analytics';
import { CustomFieldColor } from '@air/api/types';
import { Plus as PlusIcon } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { tailwindMerge } from '@air/tailwind-variants';
import { FieldArray, FormikErrors, useField } from 'formik';
import { capitalize, isArray, isString } from 'lodash';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { type HotkeyCallback, useHotkeys } from 'react-hotkeys-hook';
import { v4 as uuid } from 'uuid';

import { ReactDndDragWrapper } from '~/components/ReactDndDragWrapper';
import { CUSTOM_FIELD_MODAL_ADD_NEW_OPTION } from '~/constants/testIDs';

import { CUSTOM_FIELD_MODAL_FORM_OPTIONS } from '../CustomFieldModal';
import { CustomFieldModalOption } from '../CustomFieldModalOption';
import { CustomFieldModalFormData } from './types';

export interface CustomFieldModalFormOptionFieldsProps {
  colors: CustomFieldColor[];
  handleDisableSave: (isDisabled: boolean) => void;
  handleEditingOptions?: (isEditing: boolean) => void;
  handleFocusSave: (isFocused: boolean) => void;
}

export const CustomFieldModalFormOptionFields = memo(
  ({ colors, handleDisableSave, handleEditingOptions, handleFocusSave }: CustomFieldModalFormOptionFieldsProps) => {
    const { trackCustomFieldAddOption } = useTrackCustomFieldAddOption();
    const [editIndex, setEditIndex] = useState<number>(-1);
    const isEditingOptions = editIndex >= 0;
    const buttonRef = useRef<HTMLButtonElement>(null);

    const [_, optionsSectionMetadata] = useField<CustomFieldModalFormData['values']>(CUSTOM_FIELD_MODAL_FORM_OPTIONS);

    const { value: customFieldOptions = [], error: formError, initialValue } = optionsSectionMetadata;
    const hasEmptyCustomFieldOption = customFieldOptions.some((cf) => !cf.value);
    let customFieldOptionErrors: FormikErrors<CustomFieldModalFormData['values']> = [];
    const generalFieldError = isString(formError) ? formError : '';
    if (isArray(formError)) customFieldOptionErrors = formError;

    /** This indicates if there are any errors in the custom field options' inputs.
     * It's possible for formik to return a single object in the errors array that is undefined. As a result, we can't rely solely on the length (hence the use of `.some`).
     * Some custom fields are created directly in database by migration tools - there no length restrictions there, so ignore an error if value is same as initial
     * In that case custom field can not be saved, but at least user can click and change custom field value name
     * */
    const hasCustomFieldOptionErrors =
      !!customFieldOptionErrors &&
      customFieldOptionErrors.length > 0 &&
      customFieldOptionErrors.some((error, index) => {
        if (!!error) {
          const initialOptionValue = initialValue?.[index] ? initialValue[index].value : null;
          return initialOptionValue && customFieldOptions[index]
            ? customFieldOptions[index].value !== initialOptionValue
            : true;
        }
        return false;
      });

    const shouldDisableSave = isEditingOptions || hasCustomFieldOptionErrors || !customFieldOptions.length;

    const enterEditMode = (index: number) => {
      setEditIndex(index);
      handleFocusSave(false);
    };

    const exitEditMode = useCallback(() => {
      setEditIndex(-1);
      handleFocusSave(true);
    }, [handleFocusSave]);

    const onKeyDown = useCallback<HotkeyCallback>(() => {
      if (hasCustomFieldOptionErrors || !isEditingOptions || hasEmptyCustomFieldOption) return;
      return exitEditMode();
    }, [hasCustomFieldOptionErrors, isEditingOptions, hasEmptyCustomFieldOption, exitEditMode]);

    useHotkeys('esc,enter', onKeyDown, { enableOnFormTags: ['INPUT'] });

    useEffect(() => {
      handleDisableSave(shouldDisableSave);
    }, [handleDisableSave, shouldDisableSave]);

    useEffect(() => {
      handleEditingOptions?.(isEditingOptions);
    }, [isEditingOptions, handleEditingOptions]);

    return (
      <>
        <div className="mt-6">
          <div className="mb-1 text-12 font-semibold text-grey-12">Options *</div>
        </div>
        <FieldArray
          name="values"
          render={(arrayHelpers) => {
            const handleColorClick = (backgroundColor: string, index: number) => {
              const currentOption = { ...customFieldOptions[index] };
              const chosenColor = colors.find((c) => c.backgroundHex === backgroundColor);
              const optionWithNewColor = { ...currentOption, color: chosenColor };
              arrayHelpers.replace(index, optionWithNewColor);
            };

            const swapItems = (dragIndex: number, hoverIndex: number) => {
              arrayHelpers.move(dragIndex, hoverIndex);
              setEditIndex(-1);
            };

            return (
              <>
                <div>
                  {customFieldOptions.map((option, index) => {
                    const hasError = !!customFieldOptionErrors?.[index]?.value;
                    const isEmptyOption = !customFieldOptions[index]?.value;
                    const isSaveCurrentOptionDisabled = isEmptyOption || hasError;
                    const wasTouched = optionsSectionMetadata.touched ? customFieldOptions[index] : undefined;

                    return (
                      <ReactDndDragWrapper
                        isDraggable={!isEditingOptions}
                        key={option.id}
                        className={hasError && wasTouched && editIndex === index ? 'mb-5' : ''}
                        dragItem={{
                          type: 'custom field option',
                          index,
                          ...option,
                        }}
                        swapItems={swapItems}
                      >
                        <CustomFieldModalOption
                          isSaveCurrentOptionDisabled={isSaveCurrentOptionDisabled}
                          disableOptionClick={hasEmptyCustomFieldOption || hasCustomFieldOptionErrors}
                          colors={colors}
                          handleColorClick={handleColorClick}
                          handleSaveOption={exitEditMode}
                          handleDeleteItem={(indexToRemove) => {
                            arrayHelpers.remove(indexToRemove);
                            setEditIndex(-1);
                          }}
                          onToggleEditMode={() => enterEditMode(index)}
                          index={index}
                          inEditMode={editIndex === index}
                          option={option}
                        />
                      </ReactDndDragWrapper>
                    );
                  })}
                </div>
                <span
                  className={tailwindMerge('absolute text-12 text-red-9', !!generalFieldError ? 'block' : 'hidden')}
                  aria-hidden={!generalFieldError}
                  role="alert"
                >
                  {capitalize(generalFieldError)}
                </span>
                <Button
                  appearance="ghost"
                  className="mt-2"
                  color="grey"
                  onClick={() => {
                    const randomIndex = Math.floor(Math.random() * colors.length - 1) + 1;

                    arrayHelpers.insert(Number(customFieldOptions.length), {
                      color: {
                        backgroundHex: colors[randomIndex].backgroundHex,
                        fontHex: colors[randomIndex].fontHex,
                        name: colors[randomIndex].name,
                        id: colors[randomIndex].id,
                      },
                      value: 'a new option',
                      id: uuid(),
                      isNew: true,
                    });

                    setTimeout(() => {
                      // NOTE: This is needed this to make sure the list of custom field options grows before scrolling, and we open edit view after the button is in view
                      enterEditMode(customFieldOptions.length);
                      buttonRef.current?.scrollIntoView();
                    }, 0);

                    trackCustomFieldAddOption();
                  }}
                  ref={buttonRef}
                  prefix={<PlusIcon className="h-4 w-4" />}
                  disabled={hasEmptyCustomFieldOption || hasCustomFieldOptionErrors}
                  data-testid={CUSTOM_FIELD_MODAL_ADD_NEW_OPTION}
                >
                  Add new option
                </Button>
              </>
            );
          }}
        />
      </>
    );
  },
);

CustomFieldModalFormOptionFields.displayName = 'CustomFieldModalFormOptionFields';
