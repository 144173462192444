import { useTrackRemovedUserFromWorkspace } from '@air/analytics';
import { WorkspaceMemberResponse } from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import { TransactionModal } from '@air/zephyr';
import Router from 'next/router';
import { useState } from 'react';

import { Routes } from '~/constants/routes';
import { useSwitchWorkspace } from '~/hooks/useSwitchWorkspace';
import { useAccountContext } from '~/providers/AccountProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useRemoveWorkspaceMembers } from '~/swr-hooks/members/useRemoveWorkspaceMembers';
import { useUserByAccountIdOrEmail } from '~/swr-hooks/members/useUserByAccountIdOrEmail';
import { useWorkspaces } from '~/swr-hooks/workspaces/useWorkspaces';
import { convertUnknownToError } from '~/utils/ErrorUtils';

interface DeactivateMemberModalProps {
  member: Pick<WorkspaceMemberResponse, 'firstName' | 'lastName' | 'accountId' | 'email'>;
}

export const DeactivateMemberModal = ({
  member: { firstName, lastName, accountId, email },
  onClose,
}: AirModalProps<DeactivateMemberModalProps>) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { switchWorkspace } = useSwitchWorkspace();
  const { data: account } = useAccountContext();
  const [deactivating, setDeactivating] = useState(false);
  const { showToast } = useToasts();
  const { removeWorkspaceMembers } = useRemoveWorkspaceMembers();
  const { data: workspaces = [] } = useWorkspaces();
  const { user } = useUserByAccountIdOrEmail({ userId: accountId ?? email });
  const { trackRemovedUserFromWorkspace } = useTrackRemovedUserFromWorkspace();

  const trackingData = {
    removerId: account?.id,
    removedUserId: user?.accountId ?? '',
    removedUserRole: `${user?.type} ${user?.roles.member.displayName}`,
    wasBilled: user?.isBilled,
    wasInvitePending: user?.pending,
  };

  const onDeactivate = async () => {
    setDeactivating(true);

    const members = [accountId ? { accountId } : { email }];

    // Temporary solution: prohibit removal of members who are also guests of boards
    // setting `inactive: true` on a member would also remove the user as a guest
    if (user && user?.roles.guest.length > 0) {
      onClose();
      showToast(`Cannot deactivate a member while they are still a guest of a board`);
      return;
    }

    const workspaceId = currentWorkspace?.id;
    if (!workspaceId) {
      throw new Error('No workspace id');
    }

    // Part of a Temporary solution for sysadmins to remove themselves from workspace
    if (accountId === account?.authId) {
      if (workspaces.length < 2) {
        onClose();
        showToast(`Cannot deactivate a member from their only workspace`);
        return;
      }

      const fallBackWorkspace = workspaces.filter((workspace) => workspace.id !== workspaceId)[0];

      /**
       * Temporary fix: We'll need to revisit this flow on the
       * future + migrate this to useMutation.
       */
      try {
        await removeWorkspaceMembers({ workspaceId, members });
      } catch (error) {
        setDeactivating(false);
        showToast(convertUnknownToError(error).message, { color: 'red' });
      }

      trackRemovedUserFromWorkspace(trackingData);

      switchWorkspace({ id: fallBackWorkspace.id });
      Router.push(Routes.home);
      showToast(`${firstName && lastName ? `${firstName} ${lastName} was deactivated` : 'Member was deactivated'}`);

      return;
    }

    /**
     * Temporary fix: We'll need to revisit this flow on the
     * future + migrate this to useMutation.
     */
    try {
      await removeWorkspaceMembers({ workspaceId, members });
      trackRemovedUserFromWorkspace(trackingData);
      showToast(`${firstName && lastName ? `${firstName} ${lastName} was deactivated` : 'Member was deactivated'}`);
      onClose();
    } catch (error) {
      setDeactivating(false);
      showToast(convertUnknownToError(error).message, { color: 'red' });
    }
  };

  return (
    <TransactionModal
      onDismiss={onClose}
      modalLabel="Are you sure?"
      modalDescription={`Removing  ${
        firstName && lastName ? `${firstName} ${lastName}` : 'this member'
      } will prevent them from accessing the workspace. Any content they created will not be removed or deleted.`}
      primaryCTA={{
        children: deactivating ? 'Removing…' : 'Remove',
        onClick: onDeactivate,
        variant: 'button-filled-destructive',
        disabled: deactivating,
      }}
      secondaryCTA={{ children: 'Cancel', onClick: onClose }}
    />
  );
};
