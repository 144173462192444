import { WorkspaceMemberResponse, WorkspaceUserRole } from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import { TransactionModal } from '@air/zephyr';
import { useState } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useReactivateWorkspaceMembers } from '~/swr-hooks/members/useReactivateWorkspaceMembers';
import { sanitizeEmail } from '~/utils/EmailUtils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

interface ReactivateMemberModalProps {
  member: Pick<WorkspaceMemberResponse, 'firstName' | 'lastName' | 'accountId' | 'email'>;
  role: WorkspaceUserRole['displayName'];
}

export const ReactivateMemberModal = ({
  member: { firstName, lastName, accountId, email },
  role,
  onClose,
}: AirModalProps<ReactivateMemberModalProps>) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { showToast } = useToasts();
  const [reactivating, setReactivating] = useState(false);
  const { reactivateWorkspaceMembers } = useReactivateWorkspaceMembers();

  const onReactivate = async () => {
    setReactivating(true);
    try {
      await reactivateWorkspaceMembers({
        members: [accountId ? { accountId, inactive: false } : { email: sanitizeEmail(email), inactive: false }],
      });

      onClose();
      showToast(`${firstName} ${lastName} was reactivated`);
    } catch (error) {
      reportErrorToBugsnag({
        error,
        context: `Reactivating of members failed`,
        metadata: {
          accountId,
          email: sanitizeEmail(email),
        },
      });
      showToast('There was an error trying to reactivate this member. Please try again later.');
      setReactivating(false);
    }
  };

  return (
    <TransactionModal
      onDismiss={onClose}
      modalLabel={`Reactivate ${firstName && lastName ? `${firstName} ${lastName}` : 'this member'}?`}
      modalDescription={`They will be reactivated in the "${currentWorkspace?.name}" workspace with the "${role}" role.`}
      primaryCTA={{
        children: reactivating ? 'Reactivating…' : 'Reactivate',
        onClick: onReactivate,
        disabled: reactivating,
      }}
      secondaryCTA={{ children: 'Cancel', onClick: onClose }}
    />
  );
};
