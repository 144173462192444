import { Board } from '@air/api/types';
import { TriangleRight as TriangleRightIcon } from '@air/next-icons';
import { Box } from '@air/zephyr';
import { rgba } from 'polished';

import { DraggingItems } from '~/components/Shared/Drag/dragTypes';
import { theme } from '~/constants/theme';

interface NavBoardDragPreviewProps {
  board: DraggingItems<Board>;
}

export const NavBoardDragPreview = ({ board }: NavBoardDragPreviewProps) => {
  return (
    <Box
      tx={{
        display: 'flex',
        backgroundColor: 'pigeon050',
        borderRadius: 2,
        boxShadow: `0px 0px 8px ${rgba(theme.colors.black, 0.2)}}`,
        px: 8,
        py: 6,
        width: 240,
      }}
    >
      <TriangleRightIcon className="mr-2 w-4 shrink-0 text-grey-10" />
      <div className="truncate text-14 text-grey-11">{board.firstItem.title}</div>
    </Box>
  );
};
