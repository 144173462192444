import { WorkspaceMemberRoleDisplayName, WorkspaceUserRole } from '@air/api/types';
import { TriangleDown as TriangleDownIcon } from '@air/next-icons';
import { Badge } from '@air/primitive-badge';
import { useAirModal } from '@air/provider-modal';
import { Button } from '@air/zephyr';
import { Field, Form, Formik } from 'formik';
import { useMemo } from 'react';
import * as Yup from 'yup';

import { DropdownMenuWithSelectedLabelButton } from '~/components/Menus/DropdownMenuWithSelectedLabelButton';
import { usePlanFeature } from '~/hooks/usePlanFeature';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { useCurrentWorkspaceMember } from '~/swr-hooks/members/useCurrentWorkspaceMember';
import { useWorkspaceMemberRoles } from '~/swr-hooks/members/useWorkspaceMemberRoles';
import { getLeastPermissiveRole, isPermissionsGTE } from '~/utils/PermissionsUtils';

import { PlansModal } from '../../PlansModal/PlansModal';

interface InviteByLinkInputProps {
  onLinkGenerate: (role: WorkspaceUserRole) => void;
}

const GenerateLinkSchema = Yup.object()
  .shape({
    selectedRole: Yup.object<WorkspaceUserRole>().required('Please choose a role'),
  })
  .required();

export const InviteByLinkInput = ({ onLinkGenerate }: InviteByLinkInputProps) => {
  const { memberRoles } = useWorkspaceMemberRoles();
  const { currentWorkspaceMember } = useCurrentWorkspaceMember();
  const [showPlansModal] = useAirModal(PlansModal);
  const canHaveCommenters = usePlanFeature('commentOnlyMembers');
  const { data: subscription } = useSubscriptionContext();
  const hasSubscriptionIssue = !!subscription?.expirationReason;
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();

  const leastPermissiveRole = useMemo(
    () => (memberRoles ? getLeastPermissiveRole(memberRoles) : undefined),
    [memberRoles],
  );

  if (!memberRoles || !currentWorkspaceMember || !workspacePermissions || !leastPermissiveRole) return null;

  return (
    <div className="relative mt-4 flex items-center">
      <Formik
        validationSchema={GenerateLinkSchema}
        initialValues={{
          selectedRole: leastPermissiveRole,
        }}
        onSubmit={({ selectedRole }) => onLinkGenerate(selectedRole)}
      >
        {({ setFieldValue, values: { selectedRole } }) => (
          <div className="w-full">
            <Form>
              <div className="flex items-center">
                <Field as="children" name="selectedRole" id="selectedRole">
                  {() => (
                    <DropdownMenuWithSelectedLabelButton
                      trigger={
                        <div className="mr-3 flex h-12 flex-1 cursor-pointer items-center rounded border border-pigeon-200 bg-white px-3 hover:bg-pigeon-25">
                          <div className="flex-1 text-left text-14 text-pigeon-600">{selectedRole.displayName}</div>
                          <div className="ml-1 flex items-center">
                            <TriangleDownIcon className="h-4 w-4 text-pigeon-400" />
                          </div>
                        </div>
                      }
                      selectedOption={{
                        id: selectedRole.id,
                        label: selectedRole.displayName,
                      }}
                      options={memberRoles.map((role) => {
                        const { displayName, description, id } = role;

                        return !canHaveCommenters && displayName === WorkspaceMemberRoleDisplayName.Commenter
                          ? {
                              id,
                              onSelect: showPlansModal,
                              label: (
                                <span className="flex gap-1 overflow-visible whitespace-normal">
                                  {displayName}
                                  <Badge className="shrink-0" color="teal">
                                    Upgrade to Pro
                                  </Badge>
                                </span>
                              ),
                              description: description || undefined,
                              type: 'item',
                            }
                          : {
                              id,
                              onSelect: () => setFieldValue('selectedRole', role),
                              label: displayName,
                              description: description || undefined,
                              disabled: !isPermissionsGTE(workspacePermissions, role.permissions),
                              type: 'item',
                            };
                      })}
                    />
                  )}
                </Field>
                <Button type="submit" variant="button-filled-blue" size="large" disabled={hasSubscriptionIssue}>
                  Create link
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};
