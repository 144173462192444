import { WorkspaceMemberResponseWithRoles } from '@air/api/types';
import { memo } from 'react';

import { Avatar } from '~/components/Avatar';
import { formatFullName } from '~/utils/formatFullName';

export interface GeneralLibraryMemberProps {
  member: Pick<WorkspaceMemberResponseWithRoles, 'accountId' | 'firstName' | 'lastName' | 'avatar' | 'roles'>;
}

export const GeneralLibraryMember = memo(
  ({ member: { avatar, firstName, lastName, roles, accountId } }: GeneralLibraryMemberProps) => (
    <li className="flex items-center gap-3">
      <Avatar
        alt={`${firstName ?? ''} ${lastName ?? ''} avatar`}
        appearance="circle"
        className="shrink-0"
        size={40}
        src={avatar}
        text={`${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`}
        type="imgix"
        colorSeed={accountId}
      />
      <p className="grow truncate text-14 font-medium text-pigeon-600">
        {firstName && lastName ? formatFullName(firstName, lastName) : ''}
      </p>
      <p className="shrink-0 text-14 text-pigeon-600">{roles.member.displayName}</p>
    </li>
  ),
);

GeneralLibraryMember.displayName = 'GeneralLibraryMember';
