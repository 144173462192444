import { AirActionTrackingLocation, useTrackSavedFilterCreated } from '@air/analytics';
import { AssetSearchFiltersCalculated } from '@air/api/types';
import Router from 'next/router';
import { memo } from 'react';

import { createSavedFilterRoute } from '~/constants/routes';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useGetCustomFieldColors } from '~/swr-hooks/customFields/useGetCustomFieldColors';

import { ModalFormData, SavedFilterModal } from './components/SavedFilterModal';
import { useCreateSavedFilter } from './shared/hooks/useCreateSavedFilter';
import { DEFAULT_CRITERIA } from './shared/utils/defaultSavedFilter';

export interface CreateSavedFiltersModalProps extends AirModalProps {
  trackLocation: AirActionTrackingLocation;
  filters?: AssetSearchFiltersCalculated;
}

const MODAL_DESCRIPTION = (
  <p className="my-1.5 text-12 text-grey-11">
    Saved filters auto-update as new content is added to the workspace. All workspace members can see them, but only
    Admins can create or edit them. Try out a saved filter to find content or stay organized!
  </p>
);

const MODAL_LABEL = <div className="flex items-center text-20 font-semibold text-grey-12">Create saved filter</div>;

export const CreateSavedFilterModal = memo(({ onClose, filters, trackLocation }: CreateSavedFiltersModalProps) => {
  const { createSavedFilter } = useCreateSavedFilter();
  const { currentWorkspace } = useCurrentWorkspace();
  const { trackSavedFilterCreated } = useTrackSavedFilterCreated();
  const { data: customFieldColorListResp } = useGetCustomFieldColors();
  // NOTE: The type "CustomFieldColor" is the same as the "SavedFilterColor" type
  const colors = customFieldColorListResp?.data || [];
  const defaultColor = colors.find((c) => c.name === 'Grey') || colors[0];

  const onApplyClick = async (modalFormData: ModalFormData) => {
    if (!currentWorkspace?.id || !defaultColor) return;

    createSavedFilter.mutate(
      {
        ...modalFormData,
        color: defaultColor,
        criteria: {
          ...DEFAULT_CRITERIA,
          filters,
          workspaceId: currentWorkspace.id,
        },
      },
      {
        onSuccess: (createdSavedFilter) => {
          trackSavedFilterCreated({ trackLocation });
          onClose();
          Router.push(createSavedFilterRoute(createdSavedFilter.id));
        },
      },
    );
  };

  return (
    <SavedFilterModal
      modalDescription={MODAL_DESCRIPTION}
      modalLabel={MODAL_LABEL}
      onClose={onClose}
      onSubmit={onApplyClick}
      primaryCTACopy="Publish to workspace"
    />
  );
});

CreateSavedFilterModal.displayName = 'CreateSavedFilterModal';
