import type { Board } from '@air/api/types';
import { TreeItemProps } from '@air/component-tree';
import { Button } from '@air/primitive-button';
import { Spinner } from '@air/primitive-spinner';
import { memo, useEffect } from 'react';

import { BoardsTree, BoardsTreeProps } from '~/components/CurrentWorkspaceNav/BoardsNav/BoardsTree/BoardsTree';
import { BoardsTreeItemContent } from '~/components/CurrentWorkspaceNav/BoardsNav/BoardsTreeItem/BoardsTreeItemContent';
import { DNDMovableToBoardItem } from '~/components/Shared/Drag/DNDMovableToBoardItem/DNDMovableToBoardItem';
import { DNDParentBoard } from '~/components/Shared/Drag/DNDParentBoard';
import dragTypes from '~/components/Shared/Drag/dragTypes';
import { useBoardPermissions } from '~/hooks/useBoardPermissions';
import { useCreateSubBoard } from '~/hooks/useCreateSubBoard';
import { useFetchObjectsPermissions } from '~/hooks/useFetchObjectsPermissions';
import { isAncestorOfCentralizedBoardSelector } from '~/store/centralizedBoard/selectors';
import { useSubBoardsList } from '~/swr-hooks/boards/useSubBoardsList';
import {
  canChangeBoardAssetsOrBoardsPosition,
  canCreateBoard,
  canDeleteBoard,
} from '~/utils/permissions/boardPermissions';
import { isMobileAgent } from '~/utils/PlatformHelpers';
import { useAirSelector } from '~/utils/ReduxUtils';

const DRAG_TYPE: Record<
  'favorites' | 'workspace' | 'library',
  dragTypes.boardNavWorkspace | dragTypes.boardNavFavorites | dragTypes.boardNavLibrary
> = {
  library: dragTypes.boardNavLibrary,
  workspace: dragTypes.boardNavWorkspace,
  favorites: dragTypes.boardNavFavorites,
};

export type BoardsTreeItemProps = {
  board: Board;
  boardType: 'favorites' | 'workspace' | 'library';
  enableAutoExpand: boolean;
  index: number;
  isOpen?: TreeItemProps['open'];
  isTouched?: boolean;
  onBoardRearrange: BoardsTreeProps['onBoardRearrange'];
  onOpenChange: ({ id, isAutoExpand, isOpen }: { id: string; isAutoExpand?: boolean; isOpen: boolean }) => void;
};

export const BoardsTreeItem = memo(
  ({
    board,
    boardType,
    index,
    enableAutoExpand,
    isOpen,
    isTouched,
    onBoardRearrange,
    onOpenChange,
  }: BoardsTreeItemProps) => {
    const { boardPermissions } = useBoardPermissions({
      boardId: board.id,
    });

    const isAncestorOfCurrentBoard = useAirSelector((state) => isAncestorOfCentralizedBoardSelector(state, board.id));

    useEffect(() => {
      if (isTouched) return;

      if (isAncestorOfCurrentBoard && enableAutoExpand) {
        onOpenChange({ id: board.id, isAutoExpand: true, isOpen: true });
      }
    }, [board.id, enableAutoExpand, isAncestorOfCurrentBoard, isTouched, onOpenChange]);

    const {
      data: subBoards = [],
      isLoadingMore,
      hasMore,
      isInitialLoading,
      loadNextPage,
    } = useSubBoardsList(board.id, isOpen || false);

    useFetchObjectsPermissions({
      objects: {
        boardIds: subBoards.map((board) => board.id),
      },
    });

    const { createSubBoard } = useCreateSubBoard();

    /**
     * Show 'Create a board' button if the user canEdit, list is expanded, boards aren't being fetch
     * and or were expanded on mount and there are no subboards.
     */
    const shouldShowCreateButton =
      canCreateBoard(boardPermissions) && isOpen && subBoards.length === 0 && !isInitialLoading && !isLoadingMore;

    /**
     * Show subboards tree if the list is expanded and there are subboards for the expanded board.
     */
    const shouldShowSubBoards = isOpen && !!subBoards.length;

    /**
     * Show 'Show more' button when list is expanded, boards aren't being fetched and there
     * are more subboards that can be shown.
     */
    const shouldShowMoreButton = isOpen && hasMore && !isInitialLoading;

    return (
      <DNDMovableToBoardItem
        id={board.id}
        item={{
          type: DRAG_TYPE[boardType],
          board,
        }}
        index={index}
        enabled={!isMobileAgent}
        data-testid="BOARDS_TREE_ITEM_DND_MOVABLE_TO_BOARD_ITEM"
      >
        <div data-testid="BOARDS_TREE_ITEM_CONTENT_CONTAINER" className="relative">
          <DNDParentBoard dropLocation="board" board={board}>
            {isInitialLoading && isOpen && (
              <div className="absolute left-2 top-2 flex">
                <Spinner className="h-4 w-4 text-grey-10" />
              </div>
            )}
            <BoardsTreeItemContent
              board={board}
              index={index}
              onOpenChange={(isOpen) => {
                onOpenChange({ id: board.id, isOpen });
              }}
              open={isOpen}
            />
            {shouldShowCreateButton && (
              <Button
                data-testid="EMPTY_BOARDS_TREE_ITEM_CREATE_SUB_BOARD_BUTTON"
                onClick={() =>
                  createSubBoard({ selectedParentBoard: board, trackLocation: 'side-nav-extension-item-sub-button' })
                }
                color="grey"
                appearance="ghost"
                size="small"
                className="ml-6 inline-block truncate rounded-sm text-grey-10"
              >
                Create New Sub-Board
              </Button>
            )}
          </DNDParentBoard>
        </div>

        {shouldShowSubBoards && (
          <div className="ml-3">
            <BoardsTree
              boardType={boardType}
              parentBoardId={board.id}
              boards={subBoards}
              enableAutoExpand={enableAutoExpand}
              onBoardRearrange={onBoardRearrange}
              /**
               * We can hardcode this permission check because we know the user is inside of a sub-board so
               * it's the parent board's permissions we need to check
               */
              getCanRearrange={() =>
                canDeleteBoard(boardPermissions) || canChangeBoardAssetsOrBoardsPosition(boardPermissions)
              }
            />
          </div>
        )}

        {shouldShowMoreButton && (
          <Button
            color="grey"
            appearance="ghost"
            className="ml-9"
            isLoading={isLoadingMore}
            size="small"
            onClick={() => loadNextPage()}
          >
            Show more
          </Button>
        )}
      </DNDMovableToBoardItem>
    );
  },
);

BoardsTreeItem.displayName = 'BoardsTreeItem';
