import { SysadminClearWorkspaceButton, useSysadmin } from '@air/sysadmin';
import { memo } from 'react';

import HelpButton from '~/components/MainNavigation/HelpButton';
import { WORKSPACE_NAV_WIDTH } from '~/constants/sideNav';
import { MAIN_NAVIGATION } from '~/constants/testIDs';

import { AccountButton } from './AccountButton';
import { WorkspaceNav } from './WorkspaceNav';

export const MainNavigation = memo(() => {
  const { isInternal } = useSysadmin();

  return (
    <div
      className="flex flex-col justify-between border-r border-r-grey-4 bg-grey-2"
      data-testid={MAIN_NAVIGATION}
      style={{ width: WORKSPACE_NAV_WIDTH }}
    >
      {isInternal && (
        <div className="flex items-center justify-center pt-5">
          <SysadminClearWorkspaceButton />
        </div>
      )}

      <div className="flex-1 overflow-hidden">
        <WorkspaceNav />
      </div>
      <div className="flex flex-col items-center pb-2">
        <HelpButton />
        <AccountButton />
      </div>
    </div>
  );
});

MainNavigation.displayName = 'MainNavigation';
