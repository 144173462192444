import { Airror } from '@air/errors';

import { isAirrorInstance } from './isAirrorInstance';

/**
 * This is a helper function to call Air API - it returns data or throws if response is Airror.
 */
export const callAirApi = async <T>(apiCall: () => Promise<Airror<any> | T>): Promise<T> => {
  const data = await apiCall();

  if (isAirrorInstance(data)) {
    throw data;
  }

  return data;
};
