import { Emoji as EmojiIcon } from '@air/next-icons';
import { Emoji, EmojiPickerProps } from '@air/primitive-emoji-picker';
import { Popover } from '@air/primitive-popover';
import { Button, IconButton, Text, TXProp } from '@air/zephyr';
import dynamic from 'next/dynamic';
import { memo, ReactNode, useCallback, useMemo, useState } from 'react';

const EmojiPicker = dynamic<EmojiPickerProps>(
  () => import('@air/primitive-emoji-picker').then((mod) => mod.EmojiPicker),
  {
    ssr: false,
  },
);

export interface SavedFilterEmojiPickerProps {
  onEmojiPick?: (emoji: string) => void;
  onOpenChange?: (nextIsOpen: boolean) => void;
  emoji?: string;
  tx?: TXProp & {
    SelectedEmojiButton?: TXProp;
    SelectedEmoji?: TXProp;
  };
}

export const SavedFilterEmojiPicker = memo(
  ({ onOpenChange, onEmojiPick, emoji, tx = {} }: SavedFilterEmojiPickerProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedEmoji, setSelectedEmoji] = useState(emoji || '');
    const { SelectedEmojiButton: selectedEmojiBtnStyles, SelectedEmoji: selectedEmojiStyles } = tx;
    const isTriggerDisabled = !!!onEmojiPick;

    const iconButtonTrigger = useMemo(() => {
      return (
        <IconButton
          size="extra-small"
          tooltip={{ label: 'Insert emoji', side: 'top' }}
          tx={{
            color: 'pigeon400',
            '&:hover': { color: 'pigeon500' },
          }}
          variant="button-unstyled"
          icon={EmojiIcon}
          disabled={isTriggerDisabled}
        >
          Insert emoji
        </IconButton>
      );
    }, [isTriggerDisabled]);

    const selectedEmojiTrigger = useMemo(() => {
      return (
        <Button
          variant="button-filled-grey"
          disabled={isTriggerDisabled}
          tx={{
            display: 'flex',
            padding: 0,
            margin: 0,
            width: 30,
            height: 30,
            ...selectedEmojiBtnStyles,
          }}
        >
          <Text tx={selectedEmojiStyles}>{selectedEmoji}</Text>
        </Button>
      );
    }, [isTriggerDisabled, selectedEmoji, selectedEmojiBtnStyles, selectedEmojiStyles]);

    const trigger: ReactNode = selectedEmoji ? selectedEmojiTrigger : iconButtonTrigger;

    const handleOpenChange = useCallback(
      (nextIsOpen: boolean) => {
        onOpenChange?.(nextIsOpen);
        setIsOpen(nextIsOpen);
      },
      [onOpenChange],
    );

    const onSelect = useCallback(
      (data: Emoji) => {
        const emoji = data.native;
        onEmojiPick?.(emoji);
        setSelectedEmoji(emoji);
        handleOpenChange(false);
      },
      [handleOpenChange, onEmojiPick],
    );

    return (
      <Popover
        align="start"
        className="overflow-hidden p-0"
        side="bottom"
        open={isOpen}
        onOpenChange={handleOpenChange}
        trigger={trigger}
      >
        {isOpen && <EmojiPicker onSelect={onSelect} />}
      </Popover>
    );
  },
);

SavedFilterEmojiPicker.displayName = 'SavedFilterEmojiPicker';
