import { Error, InputPrimitive, LabelPrimitive } from '@air/zephyr';
import { ChangeEvent, useState } from 'react';

import { SAVED_FILTER_MODAL_NAME_INPUT } from '~/constants/testIDs';

import { SavedFilterEmojiPicker } from './SavedFilterEmojiPicker';

export interface SavedFilterNameInputProps {
  onEmojiPick: (emoji: string) => void;
  onInputChange: (text: string) => void;
  inputValue: string;
  selectedEmoji?: string;
  onError: (hasError: boolean) => void;
}

export const MAX_SAVED_FILTER_NAME_LENGTH = 65;

export const SavedFilterNameInput = ({
  onEmojiPick,
  onInputChange,
  inputValue,
  selectedEmoji,
  onError,
}: SavedFilterNameInputProps) => {
  const [error, setError] = useState(inputValue?.length >= MAX_SAVED_FILTER_NAME_LENGTH);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const latestInputValue = e.target.value;
    const hasError = latestInputValue?.length >= MAX_SAVED_FILTER_NAME_LENGTH;

    onInputChange(latestInputValue);
    setError(hasError);
    onError(hasError);
  };

  return (
    <>
      <LabelPrimitive for="filter-name" showAsterisk={false} size="text-ui-12">
        Name & Emoji
      </LabelPrimitive>
      <div className="relative mt-1.5 flex w-full items-center justify-evenly">
        <SavedFilterEmojiPicker
          onEmojiPick={onEmojiPick}
          emoji={selectedEmoji}
          tx={{
            SelectedEmojiButton: {
              position: 'absolute',
              left: 6,
              top: '50%',
              transform: 'translateY(-50%)',
              padding: 0,
              margin: 0,
            },
          }}
        />
        <InputPrimitive
          aria-invalid={error}
          autoFocus
          data-testid={SAVED_FILTER_MODAL_NAME_INPUT}
          name="filter-name"
          onChange={onChange}
          placeholder="Untitled saved filter"
          tx={{ width: '100%', flexGrow: 1, minWidth: 'auto', paddingLeft: 42 }}
          value={inputValue}
          variant="field-input-smol"
        />
        {error && (
          <Error
            data-testid={`APP_error`}
            errorText="Title must be less than 65 characters"
            id={'APP_DESCRIPTION'}
            isErrorVisible={error}
            tx={{ bottom: -22, left: 0 }}
          />
        )}
      </div>
    </>
  );
};
