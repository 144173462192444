import type { Board } from '@air/api/types';
import { TreeItem, TreeItemButton, TreeItemProps } from '@air/component-tree';
import { useBreakpointsContext } from '@air/provider-media-query';
import { tailwindMerge } from '@air/tailwind-variants';
import { EditableText } from '@air/zephyr-editable-text';
import { memo } from 'react';
import * as Yup from 'yup';

import { BoardsTreeItemOptions } from '~/components/CurrentWorkspaceNav/BoardsNav/BoardsTreeItem/BoardsTreeItemOptions';
import { useBoardsTreeItemIsActive } from '~/components/CurrentWorkspaceNav/BoardsNav/BoardsTreeItem/hooks/useBoardsTreeItemIsActive';
import { useBoardsTreeItemMenus } from '~/components/CurrentWorkspaceNav/BoardsNav/BoardsTreeItem/hooks/useBoardsTreeItemMenus';
import { useGoToBoardPage } from '~/hooks/useGoToBoardPage';
import { useUpdateBoard } from '~/swr-hooks/boards/useUpdateBoard';
import { containsRestrictedPathChars } from '~/utils/FileUtils';

const EditableTextBoardTitleValidationSchemaOptional = Yup.object().shape({
  ['editable-text-value']: Yup.string()
    .trim()
    .max(255, 'Cannot be longer than 255 characters')
    .test(
      'restricted-char-validation',
      'Characters : and | cannot be used',
      (val) => !containsRestrictedPathChars(val || ''),
    )
    .matches(/^[^\n]*$/, 'No line breaks allowed'),
});

export type BoardsTreeItemContentProps = Pick<TreeItemProps, 'onOpenChange' | 'open'> & {
  board: Board;
  index: number;
};

export const BoardsTreeItemContent = memo(({ board, index, onOpenChange, open }: BoardsTreeItemContentProps) => {
  const { goToBoardPage } = useGoToBoardPage();
  const { isAboveMediumScreen } = useBreakpointsContext();
  const { isActive } = useBoardsTreeItemIsActive({ board });

  const {
    updateBoard: { mutate: updateBoard },
  } = useUpdateBoard();

  const { ContextMenu, ellipsisMenuOptions, isEditing, onMenuChange, openContextMenu, setIsEditing, menuIsOpen } =
    useBoardsTreeItemMenus({
      board,
      index,
    });

  const isSmallSize = isAboveMediumScreen;
  const active = isActive || menuIsOpen;

  return (
    <>
      <TreeItem
        onOpenChange={onOpenChange}
        open={open}
        state={active ? 'active' : 'default'}
        suffix={
          isAboveMediumScreen &&
          !isEditing && (
            <div
              className={tailwindMerge(
                'hidden group-focus-within/treeItem:flex group-hover/treeItem:flex group-active/treeItem:flex',
                menuIsOpen && 'flex',
              )}
            >
              <BoardsTreeItemOptions board={board} menuOptions={ellipsisMenuOptions} onMenuChange={onMenuChange} />
            </div>
          )
        }
      >
        {isEditing ? (
          <EditableText
            data-testid="BOARDS_TREE_ITEM_CONTENT_BOARD_TITLE_EDITABLE_TEXT"
            isEditing
            isSingleLine
            label="Edit board title"
            onEditingStateChange={(isEditingState) => setIsEditing(isEditingState)}
            onReset={() => setIsEditing(false)}
            onSubmit={(values) =>
              updateBoard({
                board,
                trackLocation: 'side-nav-tree-item',
                values: { title: values['editable-text-value'] },
              })
            }
            required
            tx={{ width: '100%', height: 32, EditableTextTextarea: { whiteSpace: 'nowrap' } }}
            value={board.title}
            variant="text-ui-14"
            validationSchema={EditableTextBoardTitleValidationSchemaOptional}
          />
        ) : (
          <TreeItemButton
            onClick={() => {
              goToBoardPage({ board, trackLocation: 'sidebar' });
            }}
            className={isSmallSize ? 'h-8' : 'h-9'}
            onContextMenu={openContextMenu}
          >
            {board.title}
          </TreeItemButton>
        )}
      </TreeItem>
      {ContextMenu}
    </>
  );
});

BoardsTreeItemContent.displayName = 'BoardsTreeItemContent';
