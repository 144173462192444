import { ReactNode } from 'react';

import { Avatar } from '~/components/Avatar';
import { LibraryMemberListItem } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryMembersPanel/components/LibraryMemberList/components/LibraryMemberListItem';

interface LibraryPendingMemberListItemProps<Member extends { email: string }> {
  member: Member;
  renderSuffix?: (member: Member) => ReactNode;
}

export const LibraryPendingMemberListItem = <Member extends { email: string }>({
  member,
  renderSuffix,
}: LibraryPendingMemberListItemProps<Member>) => {
  return (
    <LibraryMemberListItem
      email={member.email}
      prefix={
        <Avatar
          alt={member.email}
          appearance="circle"
          colorSeed={member.email}
          size={40}
          src=""
          text={member.email[0]}
          type="imgix"
        />
      }
      name={member.email}
      suffix={renderSuffix?.(member)}
    />
  );
};
