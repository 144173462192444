import { ClipSource, ImporterListItem, ImportStatus } from '@air/api/types';
import { UploadStatus } from '@air/redux-uploader';
import { formatDistanceToNow } from 'date-fns';
import { upperFirst } from 'lodash';
import pluralize from 'pluralize';

import BoardThumbnail from '~/components/BoardThumbnail/BoardThumbnail';
import { FileTrackingItem } from '~/components/FileStatusTrackingPane/FileStatusTrackingPane/FileStatusTrackingPane';
import { SimpleStatus } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/FileStatusTrackingPaneHeader';
import {
  FileTrackingStatusHeaderTitles,
  HeaderStatusParams,
  sharedThumbnailStyles,
} from '~/components/FileStatusTrackingPane/shared';
import { ImportItemIcon } from '~/components/Import/ImportItemIcon';
import { LocationBoard } from '~/store/router/types';
import { parseAirDateToISO } from '~/utils/DateUtils';

const importStatusToUploadStatus = (status: ImportStatus): UploadStatus => {
  switch (status) {
    case ImportStatus.created:
      return UploadStatus.queued;
    case ImportStatus.inProgress:
      return UploadStatus.uploading;
    case ImportStatus.completed:
      return UploadStatus.completed;
    case ImportStatus.failed:
      return UploadStatus.failed;
  }
};

const sourceTypeToName = (source: ClipSource) => {
  switch (source) {
    case ClipSource.dropbox:
      return 'Dropbox';
    case ClipSource.googleDrive:
      return 'Google Drive';
    case ClipSource.box:
      return 'Box';
    default:
      return source;
  }
};

const getItemSubtitle = ({ status, startedAt, source, completedAt }: ImporterListItem) => {
  const sourceName = upperFirst(sourceTypeToName(source));

  switch (status) {
    case ImportStatus.created:
      return `${sourceName} · Beginning import...`;
    case ImportStatus.inProgress:
      return startedAt
        ? `${sourceName} · Started ${formatDistanceToNow(parseAirDateToISO(startedAt), {
            addSuffix: true,
          })}`
        : `${sourceName} · Started`;
    case ImportStatus.completed:
      return completedAt
        ? `${sourceName} · ${formatDistanceToNow(parseAirDateToISO(completedAt), {
            addSuffix: true,
          })}`
        : `${sourceName} · Completed`;
    case ImportStatus.failed:
      return `${sourceName} · Failed`;
  }
};

export const convertImportToFileTrackingPaneItem = (
  importItem: ImporterListItem,
  onRowClick: (board: LocationBoard) => void,
): FileTrackingItem => {
  const board = importItem.board ? importItem.board : { title: 'All media', id: '' };

  const numRootFiles = importItem.numRootFiles ?? 0;
  const numRootFolders = importItem.numRootFolders ?? 0;

  let title = numRootFiles || numRootFolders ? pluralize('item', numRootFiles + numRootFolders, true) : '1 item';
  if (importItem.status === ImportStatus.completed) {
    title = `${title} added`;
  }

  const boardName = board.title;
  title = `${title} to ${boardName}`;

  const thumbnail = board?.thumbnails ? board.thumbnails[0] : '';

  return {
    id: importItem.id,
    status: importStatusToUploadStatus(importItem.status),
    error: importItem.status === ImportStatus.failed ? 'Failed' : '',
    title,
    subtitle: getItemSubtitle(importItem),
    avatar: thumbnail ? (
      <BoardThumbnail
        tx={sharedThumbnailStyles}
        board={{ thumbnails: [thumbnail], title: boardName }}
        width={40}
        height={40}
        sizes="40px"
      />
    ) : (
      <ImportItemIcon source={importItem.source} />
    ),
    onRowClick: () => onRowClick(board),
  };
};

export const getImportHeaderTitles = ({
  erroredCount,
  completedCount,
  progressingCount,
}: HeaderStatusParams): FileTrackingStatusHeaderTitles => ({
  [SimpleStatus.errored]: {
    title: `Import incomplete`,
    subtitle: `${pluralize('items', erroredCount, true)} failed`,
  },
  [SimpleStatus.paused]: {
    title: `Import paused`,
  },
  [SimpleStatus.progressing]: {
    title: `${pluralize('import', progressingCount, true)} in progress`,
  },
  [SimpleStatus.completed]: {
    title: `${pluralize('import', completedCount, true)} completed`,
  },
});
