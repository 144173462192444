import { AnnouncementModal } from '@air/zephyr';

import image from '~/assets/cat-in-box.svg';
import { useFixPlanAction } from '~/hooks/useFixPlanAction';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { useCurrentWorkspaceMember } from '~/swr-hooks/members/useCurrentWorkspaceMember';
import { useWorkspaceMembers } from '~/swr-hooks/members/useWorkspaceMembers';

const FreeOutOfStorageModal = ({ onClose }: AirModalProps) => {
  const { members } = useWorkspaceMembers();
  const { currentWorkspaceMember } = useCurrentWorkspaceMember();
  const ctaProps = useFixPlanAction({ onShowPlansModal: onClose });
  const { data: subscription } = useSubscriptionContext();
  const isEnterprisePlan = subscription?.plan === 'enterprise';

  if (!members || !currentWorkspaceMember) {
    return null;
  }

  return (
    <AnnouncementModal
      imageSource={image}
      onDismiss={onClose}
      modalLabel="Unlock more storage"
      modalDescription={
        isEnterprisePlan
          ? 'You’ve reached your storage limit. Contact support to upgrade.'
          : 'You’ve reached your storage limit. Upgrade today to keep all your content on Air!'
      }
      cta={ctaProps}
      withCloseButton
    />
  );
};

export default FreeOutOfStorageModal;
