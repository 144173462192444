import { Skeleton } from '@air/component-skeleton';
import { Box, BoxProps } from '@air/zephyr';

const getLine = (percentage: string | number, index: number) => (
  <Skeleton
    height={14}
    key={index}
    style={{
      background: 'var(--pigeon100)',
      marginBottom: 24,
    }}
    width={`${percentage}%`}
  />
);

interface getBoardsNavLoadingSkeletonProps extends BoxProps {
  sectionLinesData: number[];
}

export const BoardsNavLoadingSkeleton = ({
  sectionLinesData,
  tx,
  ...restOfProps
}: getBoardsNavLoadingSkeletonProps) => {
  return (
    <Box tx={{ width: '100%', display: 'flex', flexDirection: 'column', ml: 8, mt: 8, ...tx }} {...restOfProps}>
      {sectionLinesData.map((width, index) => getLine(width, index))}
    </Box>
  );
};
