import { Form, ShortUrlResponse } from '@air/api/types';
import { Box, Button, TRANSACTION_MODAL_PRIMARY_CTA, TRANSACTION_MODAL_SECONDARY_CTA } from '@air/zephyr';
import { memo } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useRegenerateShortIdForForm } from '~/swr-hooks/shortUrl/useRegenerateShortIdForForm';
import { EditShareLinkView } from '~/types/ShareLink';

type ContentCollectionGenerateActionsProps = {
  form: Form;
  shortIdInfo: ShortUrlResponse;
  setView: (view: EditShareLinkView) => void;
};

export const ContentCollectionGenerateActions = memo(
  ({ form, shortIdInfo, setView }: ContentCollectionGenerateActionsProps) => {
    const { currentWorkspace } = useCurrentWorkspace();
    const { regenerateShortIdForForm } = useRegenerateShortIdForForm();

    return (
      <Box
        tx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 32,
          '& > *:first-child': { mr: 12 },
        }}
      >
        <Button
          variant="button-ghost-grey"
          data-testid={TRANSACTION_MODAL_SECONDARY_CTA}
          onClick={() => setView('edit')}
        >
          Cancel
        </Button>
        <Button
          variant="button-filled-blue"
          data-testid={TRANSACTION_MODAL_PRIMARY_CTA}
          onClick={() => {
            const workspaceId = currentWorkspace?.id;
            if (!workspaceId) {
              throw new Error('No workspace id');
            }
            regenerateShortIdForForm.mutate(
              {
                workspaceId,
                shortId: shortIdInfo.id,
                boardContext: form.boardId,
                formId: form.id,
              },
              {
                onSuccess: () => {
                  setView('edit');
                },
              },
            );
          }}
          isLoading={regenerateShortIdForForm.isPending}
        >
          Generate new link
        </Button>
      </Box>
    );
  },
);

ContentCollectionGenerateActions.displayName = 'ContentCollectionGenerateActions';
