import { TransactionModal } from '@air/zephyr';
import { useCallback } from 'react';

export interface CancelAllUploadsModalProps {
  onCancelUpload: () => void;
  onContinueUpload?: () => void;
}

export const CancelAllUploadsModal = ({
  onClose,
  onCancelUpload,
  onContinueUpload,
}: AirModalProps<CancelAllUploadsModalProps>) => {
  const onContinue = useCallback(() => {
    onContinueUpload?.();
    onClose?.();
  }, [onClose, onContinueUpload]);

  return (
    <TransactionModal
      onDismiss={onContinue}
      modalLabel="Cancel upload"
      modalDescription={`This will affect any assets that have not been uploaded. Completed uploads will still be in the workspace.`}
      primaryCTA={{
        children: 'Cancel Upload',
        onClick: onCancelUpload,
        variant: 'button-filled-destructive',
      }}
      secondaryCTA={{
        children: 'Continue Uploading',
        onClick: onContinue,
      }}
    />
  );
};
