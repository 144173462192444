import { SavedFilter } from '@air/api/types';
import { TransactionModal } from '@air/zephyr';
import { ReactNode, useCallback, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { Input } from '~/components/Zephyr/Input';
import { SAVED_FILTER_MODAL_DESCRIPTION_INPUT } from '~/constants/testIDs';

import { SavedFilterNameInput } from './SavedFilterNameInput';

export interface ModalFormData {
  name: string;
  icon: string;
  description: string;
}

export interface SavedFiltersModalProps extends AirModalProps {
  modalLabel: ReactNode;
  onSubmit: (savedFilter: ModalFormData) => void;
  primaryCTACopy: string;
  isEditing?: boolean;
  modalDescription?: ReactNode;
  savedFilter?: SavedFilter;
  tertiaryCTA?: JSX.Element;
}

const DEFAULT_EMOJI = '🙂';

export const SavedFilterModal = ({
  modalDescription,
  modalLabel,
  onClose,
  onSubmit,
  primaryCTACopy,
  savedFilter,
  tertiaryCTA,
  isEditing = false,
}: SavedFiltersModalProps) => {
  const [sfTitle, setSFTitle] = useState(savedFilter?.name || '');
  const [sfDescription, setSFDescription] = useState(savedFilter?.description || '');
  const [hasError, setHasError] = useState(false);
  const [emoji, setEmoji] = useState(savedFilter?.icon || DEFAULT_EMOJI);
  const hasNotBeenEdited =
    savedFilter?.name === sfTitle && savedFilter?.description === sfDescription && savedFilter?.icon === emoji;
  const emptyTitle = !sfTitle.trim();
  const isSubmitDisabled = emptyTitle || hasError || (isEditing && hasNotBeenEdited);

  const resetModalFormData = useCallback(() => {
    setSFTitle('');
    setSFDescription('');
    setEmoji(DEFAULT_EMOJI);
  }, []);

  const onApplyClick = useCallback(() => {
    onSubmit({ name: sfTitle.trim(), description: sfDescription.trim(), icon: emoji });
    resetModalFormData();
  }, [emoji, onSubmit, resetModalFormData, sfDescription, sfTitle]);

  useHotkeys('enter', onApplyClick, { enableOnFormTags: ['INPUT'], enabled: !isSubmitDisabled }, [onApplyClick]);

  return (
    <>
      <TransactionModal
        onDismiss={onClose}
        modalDescription={modalDescription}
        // NOTE: This is necessary to open the SavedFilterEmojiPicker and switch tabs (within the picker)
        dangerouslyBypassFocusLock
        primaryCTA={{
          children: primaryCTACopy,
          onClick: onApplyClick,
          disabled: isSubmitDisabled,
        }}
        secondaryCTA={{
          children: 'Cancel',
          onClick: onClose,
        }}
        tertiaryCTA={tertiaryCTA}
        modalLabel={modalLabel}
      >
        <div className="mb-4">
          <SavedFilterNameInput
            inputValue={sfTitle}
            onEmojiPick={setEmoji}
            onError={setHasError}
            onInputChange={setSFTitle}
            selectedEmoji={emoji}
          />
          <Input
            value={sfDescription}
            label="Description"
            placeholder="What is the description?"
            name="filter-desc"
            data-testid={SAVED_FILTER_MODAL_DESCRIPTION_INPUT}
            spacingStyles={{ width: '100%', mt: hasError ? 24 : 12 }}
            onChange={(e) => {
              setSFDescription(e.target.value);
            }}
          />
        </div>
      </TransactionModal>
    </>
  );
};
