import { Portal } from '@air/zephyr';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { useFileTrackingPaneBottomPos } from '~/hooks/useFileTrackingPaneBottomPos';
import { isImportRouteSelector } from '~/store/router/selectors';

import { PrivateDeleteBoardTasksPanel } from './DeleteBoardTasks/PrivateDeleteBoardTasksPanel';
import { PrivateDeleteLibraryTasksPanel } from './DeleteLibraryTasks/PrivateDeleteLibraryTasksPanel';
import { PrivateDuplicateBoardTasksPanel } from './DuplicateBoardTasks/PrivateDuplicateBoardTasksPanel';
import { ImportManager } from './Import/ImportManager';
import { PrivateRestoreBoardTasksPanel } from './RestoreBoardTasks/PrivateRestoreBoardTasksPanel';
import { PrivateRestoreLibraryTasksPanel } from './RestoreLibraryTasks/PrivateRestoreLibraryTasksPanel';
import { PrivateUploadManager } from './Upload/PrivateUploadManager';
import { PrivateZippingTasksPanel } from './ZippingTasks/PrivateZippingTasksPanel';

export const PrivateWorkspaceActivityContainer = memo(() => {
  const { uploaderRef, bottomPos } = useFileTrackingPaneBottomPos();
  const isImportRoute = useSelector(isImportRouteSelector);

  return (
    <Portal>
      <div
        ref={uploaderRef}
        className="pointer-events-none fixed inset-x-0 flex justify-end pr-4"
        style={{
          bottom: bottomPos,
          // import pane covers 'Import' button on import modal - do not show panes when it is open
          zIndex: isImportRoute ? -1 : 1,
        }}
      >
        <div className="pointer-events-auto flex flex-col gap-2">
          <PrivateZippingTasksPanel />
          <PrivateRestoreBoardTasksPanel />
          <PrivateRestoreLibraryTasksPanel />
          <PrivateDuplicateBoardTasksPanel />
          <PrivateDeleteBoardTasksPanel />
          <PrivateDeleteLibraryTasksPanel />
          <PrivateUploadManager />
          <ImportManager />
        </div>
      </div>
    </Portal>
  );
});

PrivateWorkspaceActivityContainer.displayName = 'PrivateWorkspaceActivityContainer';
