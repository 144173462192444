import { Check as CheckIcon, TriangleDown as TriangleDownIcon } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { DropdownMenu, DropdownMenuOption, DropdownMenuProps, renderDropdownItems } from '@air/primitive-dropdown-menu';
// import { Button, TXProp } from '@air/zephyr';
import { ReactNode } from 'react';

export interface DropdownMenuWithSelectedLabelButtonProps extends Omit<DropdownMenuProps, 'children' | 'trigger'> {
  trigger?: DropdownMenuProps['trigger'];
  selectedOption: {
    id: string;
    label: ReactNode;
  };
  buttonClassname?: string;
  testId?: string;
  /** Set this to true if you want to just show text of the selected item and not allow the dropdown to be used */
  disabled?: boolean;
  options: DropdownMenuOption[];
}

export const DropdownMenuWithSelectedLabelButton = ({
  disabled = false,
  options,
  selectedOption,
  testId,
  ...rest
}: DropdownMenuWithSelectedLabelButtonProps) => {
  if (disabled) {
    return <div className="px-2 py-1.5 text-14 text-grey-9">{selectedOption.label}</div>;
  }

  const optionsWithAdornment: DropdownMenuOption[] = options.map((option) =>
    option.id === selectedOption.id
      ? {
          ...option,
          suffix: <CheckIcon className="h-4 w-4 shrink-0 text-blue-10" />,
        }
      : option,
  );

  const trigger: ReactNode = (
    <Button appearance="ghost" color="grey" size="small" data-testid={testId}>
      <span className="text-14 font-normal text-grey-12">{selectedOption.label}</span>
      <div className="ml-1 flex items-center">
        <TriangleDownIcon className="h-4 w-4 text-grey-9" />
      </div>
    </Button>
  );

  return (
    <DropdownMenu trigger={trigger} {...rest}>
      {renderDropdownItems({ options: optionsWithAdornment })}
    </DropdownMenu>
  );
};
