import { TransactionModal } from '@air/zephyr';

export interface MergeAssetsModalProps extends AirModalProps {
  mergeAssets: () => void;
}
export const MergeAssetsModal = ({ onClose, mergeAssets }: MergeAssetsModalProps) => {
  return (
    <TransactionModal
      onDismiss={onClose}
      modalLabel={`Combine assets?`}
      modalDescription={`You're about to move these assets into the same version stack.`}
      primaryCTA={{
        children: `Continue`,
        autoFocus: true,
        onClick: () => {
          mergeAssets();
        },
      }}
      secondaryCTA={{ children: 'Cancel', onClick: onClose }}
    />
  );
};
