import { AnnouncementModal } from '@air/zephyr';

import image from '~/assets/people-holding-board.svg';
import { useFixPlanAction } from '~/hooks/useFixPlanAction';
import { useCurrentWorkspaceMember } from '~/swr-hooks/members/useCurrentWorkspaceMember';
import { useWorkspaceMembers } from '~/swr-hooks/members/useWorkspaceMembers';

type MemberLimitModalProps = AirModalProps;

export const MemberLimitModal = ({ onClose }: MemberLimitModalProps) => {
  const { members } = useWorkspaceMembers();
  const { currentWorkspaceMember } = useCurrentWorkspaceMember();
  const ctaProps = useFixPlanAction({ onShowPlansModal: onClose });

  if (!members || !currentWorkspaceMember) {
    return null;
  }

  return (
    <AnnouncementModal
      imageSource={image}
      onDismiss={onClose}
      modalLabel="Unlock more seats!"
      modalDescription="The Free plan allows up to 3 members in a workspace. Upgrade today and invite your whole team to Air!"
      cta={ctaProps}
      withCloseButton
    />
  );
};
