import { ClickedInviteMember, useTrackClickedInviteMember } from '@air/analytics';
import { Modal, ModalCloseButton, ModalTitle } from '@air/primitive-modal';
import { useMount } from 'react-use';

import AirTabs from '~/components/AirTabs';
import { MemberLimitMiniBanner } from '~/components/MemberLimitMiniBanner';
import { ModalBanner } from '~/components/ModalBanner';
import { InviteByEmail } from '~/components/Modals/InviteMembers/InviteMembersModal/InviteByEmail';
import { InviteByLink } from '~/components/Modals/InviteMembers/InviteMembersModal/InviteByLink';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { EnhancedSubscription, useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { canAddWorkspaceMembers, canCreateWorkspaceInviteLink } from '~/utils/permissions/workspacePermissions';

export type InviteMembersModalProps = ClickedInviteMember;

const SubscriptionIssue = ({
  title = 'Subscription Issue',
  subtitle = "There's an issue with your subscription. Please contact support to resolve.",
}) => {
  return <ModalBanner tx={{ mb: 16 }} flavor="warning" title={title} subtitle={subtitle} />;
};

const getSubscriptionIssueInfo = (subscription?: EnhancedSubscription) => {
  if (!subscription) return {};
  switch (subscription.expirationReason) {
    case 'payment_failed':
      return {
        subscriptionIssueTitle: 'Payment failed',
        subscriptionIssueMessage:
          'Your subscription is paused due to a failed payment. Visit your workspace settings or contact an admin to resolve.',
      };
    case 'subscription_cancelled':
      return {
        subscriptionIssueTitle: 'Subscription expired',
        subscriptionIssueMessage: 'Your subscription has expired. Please contact support to re-subscribe.',
      };
    default:
      return {
        subscriptionIssueTitle: 'Subscription issue',
        subscriptionIssueMessage: "There's an issue with your subscription. Please contact support to resolve.",
      };
  }
};

export const InviteMembersModal = ({ onClose, location }: AirModalProps<InviteMembersModalProps>) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const defaultTab = Number(sessionStorage.getItem('inviteModalIndex'));
  const { trackClickedInviteMember } = useTrackClickedInviteMember();
  const { data: subscription } = useSubscriptionContext();
  const hasSubscriptionIssue = !!subscription?.expirationReason;
  const { subscriptionIssueTitle, subscriptionIssueMessage } = getSubscriptionIssueInfo(subscription);
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const canAddMembers = canAddWorkspaceMembers(permissions);
  const canCreateInviteLink = canCreateWorkspaceInviteLink(permissions);

  useMount(() => {
    trackClickedInviteMember({ location });
  });

  if (!currentWorkspace || (!canAddMembers && !canCreateInviteLink)) {
    return null;
  }

  const onTabChange = (index: number) => {
    sessionStorage.setItem('inviteModalIndex', index.toString());
  };

  return (
    <Modal isOpen onDismiss={onClose} dangerouslyBypassFocusLock>
      <header className="mb-6 flex items-center justify-between gap-2">
        <ModalTitle>Invite members</ModalTitle>
        <ModalCloseButton onClick={onClose} />
      </header>

      {hasSubscriptionIssue && <SubscriptionIssue title={subscriptionIssueTitle} subtitle={subscriptionIssueMessage} />}
      <MemberLimitMiniBanner>You&apos;ve reached your member limit.</MemberLimitMiniBanner>
      <AirTabs
        isPageHeader
        tabsTitles={['Invite by email', 'Invite by link']}
        onChange={onTabChange}
        defaultIndex={defaultTab}
      >
        <InviteByEmail location={location} workspaceTitle={currentWorkspace.name} />
        <InviteByLink workspaceTitle={currentWorkspace.name} />
      </AirTabs>
    </Modal>
  );
};
