import { type NotificationUser } from '@air/api/types';
import { formatFullName, formatInitials } from '@air/utilities';

import { Avatar } from '~/components/Avatar';

export type NotificationAvatarProps = {
  actor: NotificationUser | null;
};

export const NotificationAvatar = ({ actor }: NotificationAvatarProps) => {
  const fullName = formatFullName(actor?.firstName, actor?.lastName);
  const initials = formatInitials(actor?.firstName, actor?.lastName);

  return (
    <Avatar
      alt={`${fullName} avatar`}
      className="shrink-0"
      colorSeed={actor?.id}
      size={32}
      src={actor?.avatar}
      text={initials}
      type="imgix"
    />
  );
};
