import type { LibrarySortByOptions } from '@air/api/types';
import { Modal, ModalCloseButton, ModalDescription, ModalTitle } from '@air/primitive-modal';
import { Spinner } from '@air/primitive-spinner';
import pluralize from 'pluralize';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDebouncedCallback } from 'use-debounce/lib';

import { useLibraries } from '~/components/LibraryBeta/hooks/queries/useLibraries';
import { LibraryItemContainer } from '~/components/LibraryBeta/LibraryBrowseModal/components/LibraryItem.container';
import { LibrarySearch } from '~/components/LibraryBeta/LibraryBrowseModal/components/LibrarySearch';
import { LibrarySort } from '~/components/LibraryBeta/LibraryBrowseModal/components/LibrarySort';

export const LibraryBrowseModal = ({ onClose }: AirModalProps) => {
  const [sort, setSort] = useState<keyof LibrarySortByOptions>('createdAt');
  const [search, setSearch] = useState('');
  const { ref: pageRef, inView } = useInView();

  const {
    data: libraries,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useLibraries({
    options: {
      isMemberOf: 'ALL',
      search,
      sortBy: sort,
    },
  });
  const flattenedLibraries = useMemo(() => libraries?.pages.flatMap((page) => page.data), [libraries]);
  const numberOfLibraries = flattenedLibraries?.length ?? 0;

  const [onSearch] = useDebouncedCallback((value: string) => {
    setSearch(value);
  }, 200);

  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView, isFetchingNextPage, isLoading]);

  return (
    <Modal
      className="flex max-h-[calc(100vh-10vw-32px)] flex-col !p-0"
      dangerouslyBypassFocusLock
      isOpen
      onDismiss={onClose}
    >
      <header className="flex shrink-0 flex-col gap-3 border-b border-b-pigeon-100 p-6 pb-2">
        <div className="flex justify-between gap-2">
          <ModalTitle>Browse libraries</ModalTitle>
          <ModalCloseButton onClick={onClose} />
        </div>

        <ModalDescription>
          Join libraries in your workspace to get access to their content and collaborate with others in a safe
          location.
        </ModalDescription>

        <LibrarySearch onChange={(event) => onSearch(event.currentTarget.value)} />

        <div className="flex items-center justify-between">
          {!isFetchingNextPage && !isLoading ? (
            <div className="text-12 font-semibold text-pigeon-600">{pluralize('library', numberOfLibraries, true)}</div>
          ) : (
            <div />
          )}

          <LibrarySort sort={sort} onSort={(sort) => setSort(sort)} />
        </div>
      </header>

      <section aria-live="polite" aria-busy={isFetchingNextPage} className="overflow-y-auto px-6 py-4">
        {flattenedLibraries?.map((library, index) => {
          const isLastLibrary = index === flattenedLibraries.length - 1;

          return (
            <LibraryItemContainer
              className={isLastLibrary ? 'border-b-0' : undefined}
              library={library}
              onLeave={onClose}
              key={library.id}
            />
          );
        })}

        <div ref={pageRef} />

        {isFetchingNextPage || isLoading ? <Spinner className="mx-auto my-4 text-pigeon-300" /> : null}
      </section>
    </Modal>
  );
};
