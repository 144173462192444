import { InputFile } from '@air/component-upload-dropzone';
import { TransactionModal } from '@air/zephyr';
import pluralize from 'pluralize';

import { UPLOAD_VERSIONS_MODAL } from '~/constants/testIDs';

export interface UploadVersionsModalProps extends AirModalProps {
  onUpload: (...anything: any) => void;
  onCancel: (...anything: any) => void;
  files?: InputFile[];
}

export const UploadVersionsModal = ({ onUpload, onCancel, files }: UploadVersionsModalProps) => {
  return (
    <TransactionModal
      data-testid={UPLOAD_VERSIONS_MODAL}
      onDismiss={onCancel}
      modalLabel={`Upload ${pluralize('version', (files && files.length) || 0, true)}?`}
      modalDescription={`You are about to upload ${pluralize(
        'version',
        (files && files.length) || 0,
        true,
      )} to this asset.`}
      primaryCTA={{ children: `Upload`, onClick: onUpload, autoFocus: true }}
      secondaryCTA={{ children: 'Cancel', onClick: onCancel }}
    />
  );
};
