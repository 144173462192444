import { AirActionTrackingLocation, useTrackClickedEllipsisMenuButton } from '@air/analytics';
import type { Board } from '@air/api/types';
import { useAirModal } from '@air/provider-modal';
import { useCallback, useMemo, useState } from 'react';

import { displaySlackChannelName } from '~/components/EditPrivateBoardModal/EditBoardModal/SlackIntegration/utils/displaySlackChannelName';
import { useDeleteBoardsModal } from '~/components/Modals/DeleteBoards/useDeleteBoardsModal';
import { useDuplicateBoard } from '~/components/Modals/DuplicateBoard/hooks/useDuplicateBoard';
import { useMoveItemsModal } from '~/components/Modals/MoveItemsModal/useMoveItemsModal';
import { SharePrivateBoardModal } from '~/components/SharePrivateBoardModal/SharePrivateBoardModal';
import { useGetPrivateBoardMenuOptions } from '~/hooks/getPrivateBoardMenuOptions';
import { useBoardPermissions } from '~/hooks/useBoardPermissions';
import { useCanShowInFinderOption } from '~/hooks/useCanShowInFinderOption';
import { useContextMenu } from '~/hooks/useContextMenu';
import { useCreateSubBoard } from '~/hooks/useCreateSubBoard';
import { useDownloadPrivateBoard } from '~/hooks/useDownloadPrivateBoard';
import { useDesktopAppConnectionContext } from '~/providers/DesktopAppConnectionProvider';
import { useGetBoardContainerPermissions } from '~/swr-hooks/boards/useGetBoardContainerPermissions';
import { useToggleFavoritedBoard } from '~/swr-hooks/boards/useToggleFavoritedBoard';
import { useSlackMenuOptions } from '~/utils/menuOptions/useSlackMenuOptions';
import {
  canChangeBoardDetails,
  canCreateBoard,
  canDeleteBoard,
  canDuplicateBoard,
} from '~/utils/permissions/boardPermissions';
import { canViewShortUrl } from '~/utils/permissions/shortUrlPermissions';
import { canGenerateZip } from '~/utils/permissions/taskPermissions';

interface UseBoardsTreeItemMenuProps {
  board: Board;
  index: number;
}

export const useBoardsTreeItemMenus = ({ board, index }: UseBoardsTreeItemMenuProps) => {
  const [menuIsOpen, setMenuOpen] = useState(false);
  const [contextMenuIsOpen, setContextMenuOpen] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const { showDuplicateBoardsModal } = useDuplicateBoard();
  const { showDeleteBoardsModal } = useDeleteBoardsModal();
  const { showMoveItemsModal } = useMoveItemsModal();
  const { createSubBoard } = useCreateSubBoard();
  const { toggleFavoritedBoard } = useToggleFavoritedBoard();
  const { downloadPrivateBoard } = useDownloadPrivateBoard();
  const [showSharePrivateBoardModal] = useAirModal(SharePrivateBoardModal);
  const { trackClickedEllipsisMenuButton } = useTrackClickedEllipsisMenuButton();
  const { onConnectToSlack, onConnectedToSlack, slackIntegrationData } = useSlackMenuOptions(
    menuIsOpen || contextMenuIsOpen ? board : undefined,
  );
  const { canShowOpenInFinder } = useCanShowInFinderOption();
  const { openBoardInFinder } = useDesktopAppConnectionContext();
  const onContextMenuOpenChange = useCallback(
    (isOpen: boolean) => {
      setContextMenuOpen(isOpen);
    },
    [setContextMenuOpen],
  );

  const { getBoardMenuOptions } = useGetPrivateBoardMenuOptions();
  const { boardPermissions } = useBoardPermissions({
    boardId: board.id,
  });

  const { getBoardContainerPermissions } = useGetBoardContainerPermissions();
  const boardContainerPermissions = getBoardContainerPermissions({ board });

  const getMenuOptions = useCallback(
    ({ trackLocation }: { trackLocation: AirActionTrackingLocation }) =>
      getBoardMenuOptions({
        onFavorite: !board.hasCurrentUser ? () => toggleFavoritedBoard({ board, trackLocation }) : undefined,
        onUnfavorite: board.hasCurrentUser ? () => toggleFavoritedBoard({ board, trackLocation }) : undefined,
        onShare: canViewShortUrl(boardPermissions)
          ? () => showSharePrivateBoardModal({ boardId: board.id, trackLocation })
          : undefined,
        onMove: canDeleteBoard(boardContainerPermissions)
          ? () => showMoveItemsModal({ boards: [board], trackLocation })
          : undefined,
        onRename: canChangeBoardDetails(boardPermissions) ? () => setIsEditing(true) : undefined,
        onAddSubBoard: canCreateBoard(boardPermissions)
          ? () => createSubBoard({ selectedParentBoard: board, trackLocation })
          : undefined,
        onDownload: canGenerateZip(boardPermissions)
          ? () => downloadPrivateBoard({ boardId: board.id, trackLocation })
          : undefined,
        onDuplicate: canDuplicateBoard({
          boardContext: boardPermissions,
          targetContext: boardContainerPermissions,
        })
          ? () => showDuplicateBoardsModal({ boards: [board], trackLocation })
          : undefined,
        onDelete: canDeleteBoard(boardContainerPermissions)
          ? () => showDeleteBoardsModal({ boardIds: [board.id], trackLocation, libraryId: board.library?.id })
          : undefined,
        onConnectToSlack: onConnectToSlack ? () => onConnectToSlack(trackLocation) : undefined,
        onConnectedToSlack:
          onConnectedToSlack && slackIntegrationData
            ? {
                onSelect: () => onConnectedToSlack(trackLocation),
                description: displaySlackChannelName(slackIntegrationData.channelName),
              }
            : undefined,
        onOpenOnDesktop:
          /**
           * @see https://air-labs-team.slack.com/archives/C052RDYCGAG/p1696874696775379
           */
          canShowOpenInFinder && canGenerateZip(boardPermissions) ? () => openBoardInFinder(board.id) : undefined,
      }),
    [
      board,
      boardContainerPermissions,
      boardPermissions,
      canShowOpenInFinder,
      createSubBoard,
      downloadPrivateBoard,
      getBoardMenuOptions,
      onConnectToSlack,
      onConnectedToSlack,
      openBoardInFinder,
      showDeleteBoardsModal,
      showDuplicateBoardsModal,
      showMoveItemsModal,
      showSharePrivateBoardModal,
      slackIntegrationData,
      toggleFavoritedBoard,
    ],
  );

  const contextMenuOptions = useMemo(
    () => getMenuOptions({ trackLocation: 'side-nav-extension-item-context-menu' }),
    [getMenuOptions],
  );

  const ellipsisMenuOptions = useMemo(
    () => getMenuOptions({ trackLocation: 'side-nav-extension-item-ellipsis-menu' }),
    [getMenuOptions],
  );

  const { ContextMenu, openContextMenu } = useContextMenu({
    id: `boardsTreeItem-${board.title}-${board.id}-${index}`,
    options: contextMenuOptions,
    onContextMenuOpenChange,
  });

  const onMenuChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        trackClickedEllipsisMenuButton({ location: 'sidebar' });
      }

      setMenuOpen(isOpen);
    },
    [trackClickedEllipsisMenuButton],
  );

  return {
    ellipsisMenuOptions,
    ContextMenu,
    openContextMenu,
    onMenuChange,
    isEditing,
    setIsEditing,
    menuIsOpen,
  };
};
