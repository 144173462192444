import { Search as SearchIcon } from '@air/next-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { memo } from 'react';

import { Input, InputProps } from '~/components/Zephyr/Input';

export type LibrarySearchProps = Pick<InputProps, 'onChange'>;

export const LibrarySearch = memo(({ onChange }: LibrarySearchProps) => {
  const { libraries: librariesFlag } = useFlags();

  return (
    <Input
      isLabelHidden
      label={librariesFlag ? 'Search assets & files' : 'Search libraries'}
      leftAdornment={<SearchIcon />}
      name="search-libraries"
      onChange={onChange}
      placeholder="Search libraries"
      type="search"
    />
  );
});

LibrarySearch.displayName = 'LibrarySearch';
