import { EllipsisVertical, TriangleDown as TriangleDownIcon } from '@air/next-icons';
import { DropdownMenu, DropdownMenuOption, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { Button, IconButton } from '@air/zephyr';
import { memo, ReactNode } from 'react';

import { DropdownMenuWithSelectedLabelButton } from '~/components/Menus/DropdownMenuWithSelectedLabelButton';
import {
  CONTENT_COLLECTION_FORM_MODAL_FIELD_ELLIPSIS_BUTTON,
  CONTENT_COLLECTION_FORM_MODAL_FIELD_NAME,
  CONTENT_COLLECTION_FORM_MODAL_FIELD_REQUIREMENT_BUTTON,
} from '~/constants/testIDs';

interface CollectionFormFieldProps {
  fieldName: string;
  leftAdornment?: ReactNode;
  onSelect: (isRequired: boolean) => Promise<void>;
  required: boolean;
  ellipsisMenuOptions?: DropdownMenuOption[];
  disabled?: boolean;
}

export const CollectionFormField = memo(
  ({ fieldName, leftAdornment, required, ellipsisMenuOptions, onSelect, disabled }: CollectionFormFieldProps) => {
    return (
      <div className="mb-3 flex justify-between">
        <div
          className="flex max-w-[50%] items-center text-14 text-pigeon-600"
          data-testid={`${CONTENT_COLLECTION_FORM_MODAL_FIELD_NAME}-${fieldName}`}
        >
          {leftAdornment}
          {fieldName}
        </div>
        <div className="flex">
          <DropdownMenuWithSelectedLabelButton
            selectedOption={required ? { id: 'required', label: 'Required' } : { id: 'optional', label: 'Optional' }}
            options={[
              {
                id: 'optional',
                label: 'Optional',
                onSelect: () => onSelect(false),
                type: 'item',
              },
              {
                id: 'required',
                label: 'Required',
                onSelect: () => onSelect(true),
                type: 'item',
              },
            ]}
            trigger={
              <Button
                disabled={disabled}
                variant="button-filled-grey"
                size="small"
                tx={{ mr: ellipsisMenuOptions ? 0 : 40, width: 108 }}
                data-testid={`${CONTENT_COLLECTION_FORM_MODAL_FIELD_REQUIREMENT_BUTTON}-${fieldName}`}
              >
                <div className="mr-1 text-14 text-pigeon-600">{required ? 'Required' : 'Optional'}</div>
                {!disabled && <TriangleDownIcon className="h-4 w-4 text-pigeon-600" />}
              </Button>
            }
          />
          {!!ellipsisMenuOptions && (
            <DropdownMenu
              trigger={
                <IconButton
                  variant="button-unstyled"
                  icon={EllipsisVertical}
                  size="small"
                  tx={{ ml: 8 }}
                  testId={`${CONTENT_COLLECTION_FORM_MODAL_FIELD_ELLIPSIS_BUTTON}-${fieldName}`}
                >
                  Ellipsis Dropdown
                </IconButton>
              }
            >
              {renderDropdownItems({ options: ellipsisMenuOptions })}
            </DropdownMenu>
          )}
        </div>
      </div>
    );
  },
);

CollectionFormField.displayName = 'CollectionFormField';
