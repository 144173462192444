import { WorkspaceMemberResponse, WorkspaceUserRole } from '@air/api/types';
import { TriangleDown as TriangleDownIcon } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { SelectDropdownMenu, SelectDropdownMenuProps } from '@air/primitive-dropdown-menu';
import { noop } from 'lodash';
import { matchSorter } from 'match-sorter';
import Link from 'next/link';

import { Select, SelectProps } from '~/components/Zephyr/Select/Select';
import { DefaultChipType } from '~/components/Zephyr/Select/shared/types';

export type LibraryInviteMemberChipType = Pick<DefaultChipType, 'label' | 'value'> &
  Pick<WorkspaceMemberResponse, 'accountId' | 'avatar' | 'email' | 'firstName' | 'lastName' | 'memberRole' | 'pending'>;

export type LibraryInviteMemberProps = Pick<
  SelectProps<LibraryInviteMemberChipType>,
  | 'chipRenderer'
  | 'listItemRenderer'
  | 'onCreate'
  | 'onSelectionChange'
  | 'options'
  | 'selectedOptions'
  | 'createOptionFilter'
> & {
  helperText?: string;
  isInviting?: boolean;
  onInviteMember?: () => void;
  roles?: SelectDropdownMenuProps['options'];
  selectedRole?: Pick<WorkspaceUserRole, 'displayName' | 'id'>;
  buttonText: string;
};

const filterOptionsByLabelOrEmail: Required<SelectProps<LibraryInviteMemberChipType>>['filterFunc'] = (
  items,
  inputValue,
  selectedOptions,
) => {
  const matchedOptions = matchSorter(items, inputValue, { keys: ['label', 'email'] });
  const selectedValues = selectedOptions.map((option) => option.value);
  return matchedOptions.filter((option) => !selectedValues.includes(option.value));
};

export const LibraryInviteMember = ({
  chipRenderer,
  helperText,
  isInviting,
  listItemRenderer,
  onCreate = noop,
  onInviteMember,
  onSelectionChange,
  options,
  roles,
  selectedOptions,
  selectedRole,
  createOptionFilter,
  buttonText,
}: LibraryInviteMemberProps) => {
  return (
    <div data-testid="LIBRARY_INVITE_MEMBER" className="mb-6 flex flex-col gap-4 border-b border-b-grey-5 pb-6">
      <div className="flex flex-col gap-2">
        <h3 className="text-10 font-bold uppercase tracking-wide text-grey-12">Invite members</h3>
        <p className="text-14 text-grey-10">
          Only members added to this library will be able to view it and its contents.{' '}
          <Link className="font-semibold" href="/">
            Learn more →
          </Link>
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <div className="relative grow">
            <Select
              className="w-full"
              chipRenderer={chipRenderer}
              createOptionFilter={createOptionFilter}
              /**
               * This is to prevent overlapping of the button
               */
              inputContainerClassName="!pr-32"
              isSearchable
              listItemRenderer={listItemRenderer}
              onSelectionChange={onSelectionChange}
              options={options}
              placeholder="Add by name or email..."
              renderAsInput
              createVerb="Invite"
              onCreate={onCreate}
              selectedOptions={selectedOptions}
              filterFunc={filterOptionsByLabelOrEmail}
            />
            {!!roles?.length && !!selectedRole && (
              <SelectDropdownMenu
                options={roles}
                align="start"
                sideOffset={8}
                selectedOption={selectedRole}
                trigger={
                  <Button
                    appearance="ghost"
                    className="absolute right-2 top-1/2 -translate-y-1/2 font-normal"
                    color="grey"
                    size="medium"
                    suffix={<TriangleDownIcon className="h-4 w-4" />}
                  >
                    {/* Remove library to reduce the size of the button */}
                    {selectedRole?.displayName?.replace('Library', '')}
                  </Button>
                }
              />
            )}
          </div>
        </div>
        {!!helperText && <div className="text-12 text-pigeon-400">{helperText}</div>}
        <Button disabled={!selectedOptions.length} onClick={onInviteMember} isLoading={isInviting} size="large">
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
