import { LibraryMemberWithInfo } from '@air/api/types';
import { formatFullName, formatInitials } from '@air/utilities';
import { memo, ReactNode } from 'react';

import { Avatar } from '~/components/Avatar';
import { LibraryMemberListItem } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryMembersPanel/components/LibraryMemberList/components/LibraryMemberListItem';

interface LibraryWorkspaceMemberListItemProps {
  member: LibraryMemberWithInfo;
  renderSuffix?: (member: LibraryMemberWithInfo) => ReactNode;
}

export const LibraryWorkspaceMemberListItem = memo(({ member, renderSuffix }: LibraryWorkspaceMemberListItemProps) => {
  const name = formatFullName(member.firstName, member.lastName);
  const initials = formatInitials(member.firstName, member.lastName);

  return (
    <LibraryMemberListItem
      email={member.email}
      prefix={
        <Avatar
          alt={name}
          appearance="circle"
          colorSeed={member.accountId}
          size={40}
          src={member.avatar}
          text={initials}
          type="imgix"
        />
      }
      name={name}
      suffix={renderSuffix?.(member)}
    />
  );
});

LibraryWorkspaceMemberListItem.displayName = 'LibraryWorkspaceMemberListItem';
