import { useTrackChangedPaymentMethod } from '@air/analytics';
import { Button } from '@air/primitive-button';
import { Modal, ModalCloseButton, ModalTitle } from '@air/primitive-modal';
import { useToasts } from '@air/provider-toast';
import { useQueryClient } from '@tanstack/react-query';
import { Formik } from 'formik';
import { isError } from 'lodash';
import { useState } from 'react';
import { injectStripe, ReactStripeElements } from 'react-stripe-elements';
import { useMountedState } from 'react-use';

import AirStripe from '~/components/AirStripe';
import Form from '~/components/Form';
import Spinner from '~/components/UI/Spinner';
import PaymentFields from '~/components/Workspace/Checkout/PaymentFields/PaymentFields';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { getSubscriptionKey } from '~/swr-hooks/subscriptions/useSubscription';
import { useUpdatePaymentMethod } from '~/swr-hooks/subscriptions/useUpdatePaymentMethod';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

interface UpdatePaymentMethodProps extends ReactStripeElements.InjectedStripeProps, AirModalProps<object> {}

/**
 * Keep in mind when using this HOC that Stripe is only wrapping the <AirRoute /> component
 * to avoid being loaded on the marketing site, and therefore this HOC can't be used just anywhere.
 */
const UpdatePaymentMethod = ({ onClose, stripe }: UpdatePaymentMethodProps) => {
  const { showToast } = useToasts();
  const [error, setError] = useState('');
  const isMounted = useMountedState();
  const { updatePaymentMethod } = useUpdatePaymentMethod();
  const { currentWorkspace } = useCurrentWorkspace();
  const queryClient = useQueryClient();
  const { trackChangedPaymentMethod } = useTrackChangedPaymentMethod();

  if (!stripe) {
    throw new Error(`Stripe hasn't loaded!`);
  }

  const updatePayment = async () => {
    try {
      setError('');
      const { token, error } = await stripe.createToken();

      if (!isMounted()) {
        return;
      }

      if (error) {
        throw error.message;
      }

      if (isError(token) || !token) {
        throw token || 'A token failed to be created';
      }

      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      await updatePaymentMethod({ workspaceId, token });

      if (!isMounted()) {
        return;
      }

      showToast('Your payment information was successfully updated');

      trackChangedPaymentMethod();

      queryClient.invalidateQueries({ queryKey: getSubscriptionKey(workspaceId) });

      onClose();
    } catch (error) {
      if (!isMounted()) {
        return;
      }

      const _error = reportErrorToBugsnag({
        error,
        context: `Failed to update payment method`,
      });
      setError(_error.message);
    }
  };

  return (
    <Modal isOpen onDismiss={onClose}>
      <header className="mb-4 flex items-center justify-between gap-4">
        <ModalTitle>Update payment method</ModalTitle>
        <ModalCloseButton className="shrink-0" onClick={onClose} />
      </header>
      <Formik initialValues={{}} onSubmit={() => updatePayment()}>
        {({ isValid, isSubmitting }) => (
          <Form id="update-payment-form">
            <PaymentFields />
            {!!error && <span className="mt-4 text-14 text-red-9">{error}</span>}
            <footer className="mt-8 flex items-center justify-end gap-2">
              <Button appearance="ghost" color="grey" onClick={onClose} size="large">
                Cancel
              </Button>
              <Button
                appearance="filled"
                color="blue"
                disabled={!isValid}
                isLoading={isSubmitting}
                size="large"
                type="submit"
              >
                Confirm
              </Button>
            </footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const StripedUpdatePaymentMethod = injectStripe((props: UpdatePaymentMethodProps) => (
  <UpdatePaymentMethod {...props} />
));

const UpdatePaymentMethodWrapper = (props: AirModalProps<object>) => {
  return (
    <AirStripe>
      {({ stripeIsLoaded }) =>
        stripeIsLoaded ? (
          <StripedUpdatePaymentMethod {...props} />
        ) : (
          <div className="my-8 flex justify-center">
            <Spinner />
          </div>
        )
      }
    </AirStripe>
  );
};

export default UpdatePaymentMethodWrapper;
