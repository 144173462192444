import { LibraryWithPermissions } from '@air/api/types';
import classnames from 'classnames';
import { ReactNode, useState } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';

import { useLibraryPermissionsCache } from '~/hooks/useLibraryPermissionsCache';
import { selectedBoardsSelector } from '~/store/selectedItems/selectors';
import { canCreateBoard } from '~/utils/permissions/boardPermissions';
import { useAirStore } from '~/utils/ReduxUtils';

import { boardDragTypes, DropData, DropLocation, isBoardDragType } from './dragTypes';

export type DNDParentLibraryInterface = Pick<LibraryWithPermissions, 'id' | 'title' | 'permissions'>;

export interface DNDParentLibraryProps {
  library: DNDParentLibraryInterface;
  children: ReactNode;
  dropLocation: DropLocation;
}

export const DNDParentLibrary = ({ library, children }: DNDParentLibraryProps) => {
  const [canDrop, setCanDrop] = useState(false);
  const store = useAirStore();
  const selectedBoards = selectedBoardsSelector(store.getState());
  const { getLibraryPermissions } = useLibraryPermissionsCache();

  const checkIfCanDrop = (monitor: DropTargetMonitor) => {
    const item = monitor.getItem();
    const libraryPermissions = getLibraryPermissions(library.id);

    /**
     * Prevent the user from dragging a board into a library
     * that they don't have permission to edit.
     */
    if (!canCreateBoard(libraryPermissions)) {
      return false;
    }

    // this function determines whether or not the dragged item can be dropped here
    // we only want to check if there are selected boards or the user has physically dragged a board
    if (isBoardDragType(item.type) || selectedBoards.length) {
      return item?.firstItem?.library?.id !== library.id;
    }
    return false;
  };

  const [{ isOver }, drop] = useDrop({
    accept: boardDragTypes,
    drop: (): DropData => ({
      type: 'move-to-library',
      location: 'library',
      data: library,
    }),
    hover: (_props, monitor) => {
      const canDropItem = checkIfCanDrop(monitor);
      if (canDropItem !== canDrop) {
        setCanDrop(canDropItem);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    canDrop: (_props, monitor) => checkIfCanDrop(monitor),
  });

  return (
    <div
      data-draggable="true"
      className={classnames('relative h-full', {
        'hovering-drop': isOver && canDrop,
      })}
      ref={drop}
    >
      {children}
    </div>
  );
};
